import { Link } from 'react-router-dom';
import { useToggle } from 'react-use';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';

import { ReactComponent as IconHashare } from 'images/icons/logo6.svg';

import UserMenu from 'components/UserMenu';

import useGlobalState from 'hooks/useGlobalState';

import { IMPRINT_PAGE_ROUTE, INFO_PAGE_ROUTE, PRICING_PAGE_ROUTE } from 'constants/routes';

import styles from './NavBar.module.scss';

interface IProps {
  className?: string;
}

const NavBar = (props: IProps) => {
  const { className } = props;
  const { t } = useTranslation();

  const [globalState] = useGlobalState();
  const { isAuthorized } = globalState;

  const [isMobileMenuOpened, toggleMobileMenuState] = useToggle(false);

  const handleToggleMenuClick = () => {
    toggleMobileMenuState();
  };

  return (
    <>
      <div className={cn(styles.container, className)}>
        <a href="/" className={styles.logoLink}>
          <IconHashare className={styles.hasharelogo}></IconHashare>
        </a>

        <div
          className={cn(styles.menuToggle, { [styles.menuToggleOpened]: isMobileMenuOpened })}
          onClick={handleToggleMenuClick}
        >
          <div className={styles.menuToggleLine}></div>
        </div>

        <div
          className={cn(styles.menu, {
            [styles.menuVisible]: isMobileMenuOpened,
            [styles.menuAuthorized]: isAuthorized,
          })}
        >
          <a href="/">{t('navbarWebAppItemTitle')}</a>
          <a
            href="https://apps.apple.com/de/app/hashare/id1619279459"
            target="_blank"
            rel="noreferrer"
          >
            {t('navbariOSAppItemTitle')}
          </a>
          <Link to={INFO_PAGE_ROUTE}>{t('navbarInfoItemTitle')}</Link>
          <Link to={PRICING_PAGE_ROUTE}>{t('navbarPricingItemTitle')}</Link>
          <a href="mailto:help@hash.cloud" target="_blank" rel="noreferrer">
            {t('navbarHelpItemTitle')}
          </a>
          <Link to={IMPRINT_PAGE_ROUTE}>{t('navbarImprintItemTitle')}</Link>

          <UserMenu className={styles.userMenu} />
        </div>
      </div>
    </>
  );
};

export default NavBar;
