const de = {
  hey: 'hey,',
  you: 'du!',
  nofiles: 'Keine Dateien gefunden für diesen',
  noFilesFound: 'Keine Dateien gefunden.',
  folder: ' Ordner.',
  hashtag: ' hashtag.',
  uploadfiles: 'Dateien hochladen',
  or: 'oder',
  back: 'Zurück',
  searchAnotherHashtag: 'Einen anderen #hashtag suchen',
  passwordEnterTitle: 'Dieser #hashtag ist geschützt.',
  passwordEnterPromt: 'Bitte gebe das Passwort ein, um fortzufahren.',
  password: 'Passwort:',
  cancel: 'Abbrechen',
  submit: 'OK',
  search: 'Suchen',
  hashtagDoesntExist: 'Dieser #hashtag existiert nicht.',
  folderDoesntExist: 'Dieser Ordner existiert nicht.',
  createHashtag: 'Hashtag erstellen',
  checkHashtag: '#hashtag',
  instructions: 'Anleitung:',
  downloadApp: '1. Lade die App und erstelle einen #hashtag.',
  createAndUpload: '2. Lade Dateien auf diesen #hashtag.',
  typeIn: '3. Schaut euch hier oder in der App alle Dateien an.',
  nameIsAlreadyTaken: 'Dieser Name ist bereits vergeben. Bitte wählen Sie einen anderen.',
  fileWasSuccessfullyRenamed: 'Datei wurde erfolgreich umbenannt',
  fileWasSuccessfullyDeleted: 'Datei wurde erfolgreich gelöscht',
  failedConnectToCloud:
    'Wir konnten keine Verbindung zur Cloud herstellen. Bitte laden Sie die Seite neu und versuchen Sie es erneut oder wenden Sie sich an unser Support-Team.',
  navbarWebAppItemTitle: 'web app',
  navbariOSAppItemTitle: 'iOS app',
  navbarInfoItemTitle: 'info',
  navbarPricingItemTitle: 'preise',
  navbarHelpItemTitle: 'hilfe',
  navbarImprintItemTitle: 'impressum',
  defaultErrorMessage:
    'Etwas ist schief gelaufen. Bitte versuchen Sie es später noch einmal oder kontaktieren Sie unser Support-Team.',
  fileType: 'Datei',
  folderType: 'Ordner',
  authModalTitle: 'Bitte wählen Sie eine Option, um fortzufahren',
  signIn: 'Anmelden',
  signInWithApple: 'Mit Apple anmelden',
  signInWithGoogle: 'Mit Google anmelden',
  createFolderInputPlaceholder: 'Ordnername',
  createFolderModalTitle: 'Bitte geben Sie einen Ordnernamen ein',
  hashtagAlreadyExists:
    'Der angegebene Hashtag wurde bereits erstellt. Bitte wählen Sie einen anderen Hashtag-Namen',
  createHashtagModalTitle: 'Denken Sie an einen neuen Hashtag',
  createHashtagRequirement1: 'Mindestens 6 Zeichen',
  createHashtagRequirement2: 'Mindestens 1 Zahl',
  createHashtagRequirement3: 'Keine Leerzeichen oder Sonderzeichen',
  createHashtagInputPlaceholder: '#hashtag',
  dropzoneText: 'Ziehen Sie Ihre Dateien hierhin',
  dropzoneSmallText: 'Maximal {{MAX_FILES_PER_UPLOAD}} Dateien',
  dropzoneBtnText: ' Dateien durchsuchen',
  fileMoreActionsTitle: 'Weitere Aktionen',
  fileActionDelete: 'Löschen',
  fileActionEdit: 'Bearbeiten',
  fileRenameModalTitle: 'Umbenennen',
  notFoundPageTitle: 'Die angeforderte Seite konnte nicht gefunden werden!',
  notFoundPageSubtitle: 'Besuchen Sie stattdessen unsere Homepage',
  notFoundPageBtnText: 'Zur Homepage gehen',
  pageDropzoneText: 'Ziehen Sie Ihre Dateien hierhin',
  fileUploaded: 'Datei erfolgreich hochgeladen!',
  filesUploaded: 'Dateien erfolgreich hochgeladen!',
  fileUploadModalSelectedFiles: 'Ausgewählte Dateien',
  fileUploadModalClearFiles: 'Dateien löschen',
  addFolder: 'Ordner hinzufügen',
  addFiles: 'Dateien hinzufügen',
  profileUsername: 'Benutzername',
  profileUsedStorage: 'Benutzter Speicher',
  profileOutOf: 'von',
  profileSignOut: 'Abmelden',
  profileSupport: 'Unterstützung',
  outOfStorageTitle: 'Speicherplatz voll!',
  outOfStorageText:
    'Sie haben nicht genügend Speicherplatz, um diese Datei hochzuladen. Sie können diesen erweitern, indem Sie auf einen anderen Speicherplan umsteigen.',
  checkPricingPlans: 'Überprüfen Sie unsere Pläne',
  setHashtagPasswordModalTitle: 'Bitte geben Sie ein Passwort für Ihren Hashtag ein',
  protectWithPassword: 'Hashtag mit einem Passwort schützen',
  removePasswordProtection: 'Passwortschutz entfernen',
  hashtagPasswordUpdated: 'Das Passwort wurde erfolgreich gesetzt',
  hashtagPasswordReset: 'Das Passwort wurde erfolgreich entfernt',
  settings: 'Einstellungen',
  buyStorage: 'Speicher kaufen',
  deleteAccount: 'Konto löschen',
  usernameChangeModalTitle: 'Ihr Benutzername wird mit den hochgeladenen Dateien verknüpft',
  profileAccount: 'Konto',
  manageSubscription: 'Abonnement verwalten',
  subscriptionSuccessMessage:
    'Abonnement erfolgreich! Ihr Speicher wurde gemäß Ihrem ausgewählten Plan aktualisiert.',
  pricingPageTitle: 'Beginnen Sie mit <span>hashcloud+</span>',
  pricingPageSubtitle: 'Einfache Preisgestaltung, jederzeit kündbar',
  pricingFaqTitle: 'Häufig gestellte Fragen',
  pricingFaqQuestion1: 'Wie kann ich mehr Datenvolumen erwerben?',
  pricingFaqAnswer1:
    'Um Dateien in hashcloud hochzuladen, melden Sie sich mit einer der verfügbaren Methoden an. Sie können bis zu 1 GB kostenlos hochladen. Für mehr Speicherplatz können Sie für nur 4,99 € pro Monat auf 1000 GB upgraden.',
  pricingFaqQuestion2: 'Wie funktioniert die Bezahlung?',
  pricingFaqAnswer2:
    'Das jeweilige Abo wird über den Apple AppStore abgeschlossen. Die Zahlung erfolgt über deine Apple ID und deine bei Apple hinterlegte Zahlungsweise.',
  pricingFaqQuestion3: 'Wie wird das Datenvolumen berechnet',
  pricingFaqAnswer3:
    'Hashcloud berechnet nur die Dateien, die du hochgeladen hast. Das Ansehen, herunterladen, ändern oder löschen wird nicht berechnet. Beispiel: Hast du in 10 verschiedenen Hashtags jeweils eine Datei mit jeweils 1MB hochgeladen, so hast du 10 MB an Datenvolumen verbraucht.',
  contactUsTitle: 'Hast du Fragen?',
  contactUsText: 'Schreib uns eine E-Mail und wir melden uns umgehend bei dir.',
  contactUsBtnText: 'Kontaktiere uns',
  subscribe: 'Abonnieren',
  selected: '✅ Ausgewählt',
  active: '✅ Aktiv',
  select: 'Auswählen',
  per: 'pro',
  month: 'Monat',
  year: 'Jahr',
  currentPlan: 'Aktueller Plan',
  mostPopular: 'Am beliebtesten',
  subscriptionInactiveMessage:
    'Ihr Abonnement ist nicht mehr aktiv. Ihr verfügbarer Speicherplatz wurde auf 1 GB zurückgesetzt.',
  monthly: 'Monatlich',
  yearly: 'Jährlich',
  standardPlanName: 'Standard plan',
  standardPlanDescription: '10 GB Speicher inklusive',
  plusPlanName: 'Plus plan',
  plusPlanDescription: '100 GB Speicher inklusive',
  ultraPlanName: 'Ultra plan',
  ultraPlanDescription: '1000 GB Speicher inklusive',
  close: 'Schließen',
  company: 'Firma',
  address: 'Adresse',
  contacts: 'Kontakt',
  representedBy: 'Vertreten durch',
  registryEntry: 'Registereintrag',
  vatId: 'Umsatzsteuer-Identifikationsnummer gemäß §27a Umsatzsteuergesetz',
  contentResponsible: 'Inhaltlich Verantwortlicher gemäß § 55 Abs. 2 RStV',
  disputeResolution: 'Streitschlichtung',
  hashcloudCompanyName: 'Miosga Software UG (haftungsbeschränkt)',
  hashcloudCompanyAddress: 'Karlsburg 16\n24398 Winnemark\nDeutschland',
  hashcloudCompanyContacts: 'E-Mail: help@hash.cloud\nTelefon: +49 (0) 151/22306315',
  hashcloudCompanyRepresentative: 'Tim Miosga',
  hashcloudCompanyRegistryEntry: 'Amtsgericht Kiel, HRB 26713 KI',
  hashcloudCompanyVatId: 'DE297431012',
  hashcloudCompanyContentResponsible: 'Tim Miosga',
  imprintPageTitle: 'Impressum',
  termsOfUse: 'Nutzungsbedingungen',
  privacyPolicy: 'Datenschutzerklärung',
  eula: 'EULA (Endbenutzer-Lizenzvertrag)',
  downloadDeutsch: 'Herunterladen (Deutsch)',
  downloadEnglish: 'Herunterladen (Englisch)',
  infoPageHeroTitle: 'Teile Dateien, einfach per <em>#hashtag</em>.',
  infoPageHeroFootnote:
    'Mit hashcloud kannst du  schnell und sicher Dateien teilen. Hier erfährst du, wie es funktioniert.',
  infoPageSection1Title: 'Hashtag erstellen',
  infoPageSection1Content:
    'Denke dir einen Hashtag aus, den du deiner Familie, Freunden oder Arbeitskollegen weitergeben kannst.',
  infoPageSection2Title: 'Dateien hochladen',
  infoPageSection2Content:
    'Lade Dateien hoch, die du mit anderen teilen möchtest. Es können z.B. Fotos, Videos, Präsentationen, pdf-, oder zip-Dateien hochgeladen werden.',
  infoPageSection3Title: 'Zugriff von überall',
  infoPageSection3Content:
    'Jeder der deinen Hashtag kennt kann auf die hochgeladenen Dateien zugreifen, selbst welche hochladen, löschen und bearbeiten. Über die iOS- oder WebApp.',
  infoPageFeature1Title: 'Schnelles Teilen von Dateien',
  infoPageFeature1Content:
    'Möchtest du Dateien teilen, erstelle einfach einen Hashtag, lade darüber die Dateien hoch und teile den Hashtag mit anderen Leuten.',
  infoPageFeature2Title: 'So sicher wie du es möchtest',
  infoPageFeature2Content:
    'Entscheide selbst wie lang und komplex dein Hashtag werden soll, um ihn eher für private oder öffentliche Zwecke zu nutzen.',
  infoPageFeature3Title: 'Hosting in Deutschland',
  infoPageFeature3Content:
    'All deine Dateien werden auf Servern in Deutschland gespeichert und unterliegen den höchsten europäischen Datenschutz-bestimmungen.',
  imprint: 'Impressum',
  legalInfromation: 'Rechtliches',
  copyright: 'Urheberrecht ©',
  hashcloudDirector: 'Geschäftsführer: Tim Miosga',
  hashcloudFooterAddress: 'Chausseestr. 29, 10115 Berlin, Deutschland',
  hashcloudFooterRegistryNumber: 'HRB 26713 KI',
  hashcloudFooterVatId: 'USt-IdNr: DE297431012',
  eulaShort: 'EULA',
  fileActionReport: 'Melden',
  reportHashtagModalTitle: 'Möchten Sie diesen Hashtag melden?',
  reportHashtagTooltip: 'Einen Hashtag melden',
  yes: 'Ja',
  no: 'Nein',
  fileActionsBarDownload: 'Herunterladen',
  fileActionsBarDownloadNumFiles: 'Lade {{count}} Dateien herunter',
  fileActionsBarDelete: 'Löschen',
  fileActionsBarDeleteNumFiles_one: 'Lösche {{count}} Datei',
  fileActionsBarDeleteNumFiles_other: 'Lösche {{count}} Dateien',
  fileActionsClearSelection: 'Auswahl aufheben',
  fileActionsBarNumFilesSelected_one: '{{count}} Datei ausgewählt',
  fileActionsBarNumFilesSelected_other: '{{count}} Dateien ausgewählt',
  fileActionsBarNumOwnFilesSelected: '{{count}} von Ihnen hochgeladen',
  filesWereSuccessfullyDeleted: 'Dateien wurden erfolgreich gelöscht',
  fileActionsBarFilesDeleteModalTitle_one: 'Möchten Sie wirklich {{count}} Datei löschen?',
  fileActionsBarFilesDeleteModalTitle_other: 'Möchten Sie wirklich {{count}} Dateien löschen?',
  fileActionDownload: 'Herunterladen',
  fileActionOpen: 'Öffnen',
  fileActionsBarOpen: 'Öffnen',
  miosgaCompanyName: 'Miosga Software UG (haftungsbeschränkt)',
  miosgaCompanyAddress: 'Chausseestr. 29, 10115 Berlin, Deutschland',
  miosgaCompanyPhone: '+49 (0) 151 22306315',
  miosgaCompanyEmail: 'info@miosga-software.com',
  miosgaOtherCommunications: 'WhatsApp (+49 (0) 151 22306315)',
  miosgaCompanyDirector: 'Tim Alexander Miosga',
  miosgaCompanyRegistry: 'Amtsgericht Kiel, HRB 26713 KI',
  miosgaCompanyVatId: 'DE297431012',
  miosgaCompanySmallBusiness: 'Kleinunternehmer gemäß § 19 UStG',
  miosgaCompanyVoluntarySelfControl:
    'Die Miosga Software UG (haftungsbeschränkt) ist Mitglied der Freiwilligen Selbstkontrolle Multimedia-Diensteanbieter e. V. (FSM).',
  miosgaOfficeAddress: 'Beuthstr. 6, 10117 Berlin',
  disputeResolutionInfo:
    'Die Europäische Kommission stellt eine Plattform zur Online-Streitbeilegung (OS) bereit: <Link>https://ec.europa.eu/consumers/odr</Link>. Zur Teilnahme an einem Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle sind wir nicht verpflichtet und nicht bereit.',
  phone: 'Telefon',
  email: 'E-Mail',
  otherCommunications: 'Weitere Kommunikationsmittel',
  director: 'Geschäftsführer',
  registry: 'Handelsregister',
  smallBusiness: 'Kleinunternehmer',
  voluntarySelfControl: 'Freiwillige Selbstkontrolle',
  officeAddress: 'Geschäftsstelle',
  miosgaFSMOfficeAddress: 'Geschäftsstelle: Beuthstr. 6, 10117 Berlin',
  miosgaFSMContact:
    'E-Mail: <mailLink>office@fsm.de</mailLink> Internet: <webLink>https://www.fsm.de</webLink>',
  miosgaFSMDetails:
    'Die FSM widmet sich insbesondere dem Jugendschutz und der Bekämpfung illegaler, jugendgefährdender und entwicklungsbeeinträchtigender Inhalte in Online-Medien. Auf die Bestellung eines Jugendschutzbeauftragten wird gemäß § 7 Abs. 2 JMStV verzichtet. Zur Wahrnehmung der Aufgaben des Jugendschutzbeauftragten hat sich die FSM verpflichtet.',
  mainPageTitle: 'Teilen Dateien via <highlight>#hashtags</highlight>',
  mainPageSubtitle:
    'Erstelle einen hashtag, lade deine Dateien hoch, <br> und greife sofort von überall darauf zu – im Web oder in der App.',
  mainPageFormTitle: 'Wie lautet dein #hashtag?',
  mainPageFormBtn1Text: 'Dateien anzeigen',
};

export default de;
